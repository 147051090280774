import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchModuleTE = <E = Error>(
  { userId, appId, moduleId, params = '', sessionToken }: { userId: string; appId: string; moduleId: string; params?: string; sessionToken?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Modules.GetModuleResponse> =>
  fetchTE<CF.API.Modules.GetModuleResponse, E>(
    {
      sessionToken,
      path: `/api/_api/v2/users/${userId}/apps/${appId}/modules/${moduleId}${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
