import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const unStarModuleTE = <E = Error>(
  { userId, appId, body }: { userId: string; appId: string; body: { module_ids: string[] } },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Models.ModelUnstarResponse> =>
  fetchTE<CF.API.Models.ModelUnstarResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/module_stars`,
      method: 'DELETE',
      body,
    },
    onRejected,
  );
