import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE, fetchApi } from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listModulesTE = <E = Error>(
  { userId, appId, params = '', sessionToken }: { userId?: string; appId?: string; params?: string; sessionToken?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Modules.ListModulesResponse> =>
  fetchTE<CF.API.Modules.ListModulesResponse, E>(
    {
      path: `/api/_api/v2${userId ? `/users/${userId}` : ''}${appId ? `/apps/${appId}` : ''}/modules${params ? `?${params}` : ''}`,
      method: 'GET',
      sessionToken,
    },
    onRejected,
  );

export const apiListModules = ({
  userId,
  params = '',
  sessionToken,
}: {
  userId?: string;
  params?: string;
  sessionToken?: string;
}): Promise<CF.API.Modules.ListModulesResponse> =>
  fetchApi({
    path: userId ? `/api/_api/v2/users/${userId}/modules${params ? `?${params}` : ''}` : `/api/_api/v2/modules${params ? `?${params}` : ''}`,
    sessionToken,
    method: 'GET',
  });
