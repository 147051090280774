import { BreadCrumbJsonLdProps } from 'next-seo';

export function buildAppBreadcrumbs(props: AppBreadcrumbProps): BreadcrumbItems {
  let { host, userOrOrgId, appId, modelId = '', workflowId = '', moduleId = '', conceptId = '' } = props;

  host = encodeURIComponent(host);
  userOrOrgId = encodeURIComponent(userOrOrgId);
  appId = encodeURIComponent(appId);
  modelId = encodeURIComponent(modelId);
  workflowId = encodeURIComponent(workflowId);
  moduleId = encodeURIComponent(moduleId);
  conceptId = encodeURIComponent(conceptId);

  return [
    {
      position: 1,
      name: 'My Apps',
      item: `https://${host}`,
    },
    {
      position: 2,
      name: userOrOrgId,
      item: `https://${host}/${userOrOrgId}`,
    },
    {
      position: 3,
      name: appId,
      item: `https://${host}/${userOrOrgId}/${appId}`,
    },
    ...(modelId
      ? [
          {
            position: 4,
            name: 'Models',
            item: `https://${host}/${userOrOrgId}/${appId}/models`,
          },
          {
            position: 5,
            name: modelId,
            item: `https://${host}/${userOrOrgId}/${appId}/models/${modelId}`,
          },
        ]
      : []),
    ...(workflowId
      ? [
          {
            position: 4,
            name: 'Workflows',
            item: `https://${host}/${userOrOrgId}/${appId}/workflows`,
          },
          {
            position: 5,
            name: workflowId,
            item: `https://${host}/${userOrOrgId}/${appId}/workflows/${workflowId}`,
          },
        ]
      : []),
    ...(moduleId
      ? [
          {
            position: 4,
            name: 'Modules',
            item: `https://${host}/${userOrOrgId}/${appId}/modules`,
          },
          {
            position: 5,
            name: moduleId,
            item: `https://${host}/${userOrOrgId}/${appId}/modules/${moduleId}`,
          },
        ]
      : []),
    ...(conceptId
      ? [
          {
            position: 4,
            name: 'Modules',
            item: `https://${host}/${userOrOrgId}/${appId}/concepts`,
          },
          {
            position: 5,
            name: moduleId,
            item: `https://${host}/${userOrOrgId}/${appId}/concepts/${conceptId}`,
          },
        ]
      : []),
  ];
}

export interface AppBreadcrumbProps {
  host: string;
  userOrOrgId: string;
  appId: string;
  modelId?: string;
  workflowId?: string;
  moduleId?: string;
  conceptId?: string;
}

export function buildCommunityBreadcrumbs(props: CommunityBreadcrumbProps): BreadcrumbItems {
  let { host } = props;
  const page = props.page;

  host = encodeURIComponent(host);

  return [
    {
      position: 1,
      name: 'My Apps',
      item: `https://${host}`,
    },
    ...(page === 'featured'
      ? [
          {
            position: 2,
            name: 'Featured',
            item: `https://${host}/explore`,
          },
        ]
      : []),
    ...(page === 'apps'
      ? [
          {
            position: 2,
            name: 'Featured Apps',
            item: `https://${host}/explore/apps`,
          },
        ]
      : []),
    ...(page === 'models'
      ? [
          {
            position: 2,
            name: 'Featured Models',
            item: `https://${host}/explore/models`,
          },
        ]
      : []),
    ...(page === 'workflows'
      ? [
          {
            position: 2,
            name: 'Featured Workflows',
            item: `https://${host}/explore/workflows`,
          },
        ]
      : []),
  ];
}

export interface CommunityBreadcrumbProps {
  host: string;
  page: 'featured' | 'models' | 'workflows' | 'modules' | 'apps';
}

export type BreadcrumbItems = BreadCrumbJsonLdProps['itemListElements'];
