import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const starModuleTE = <E = Error>(
  { userId, appId, body }: { userId: string; appId: string; body: { module_stars: { module_id: string }[] } },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Models.ModelStarResponse> =>
  fetchTE<CF.API.Models.ModelStarResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/module_stars`,
      method: 'POST',
      body,
    },
    onRejected,
  );
